<template>
  <div>
    <b-row>
      <b-col>
        <b-card title="Liste des ventes">
          <vue-good-table-vue
            :columns="columns"
            :rows="rows"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import VueGoodTableVue from '@/views/m-components/listes/VueGoodTableVue.vue'
import { BCard, BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'MainVue',
  components: {
    BRow, BCol, BCard, VueGoodTableVue,
  },
  data() {
    return {
      columns: [
        {
          label: 'Référence',
          field: 'reference',
          type: 'text',
          filterable: true,
        },
        {
          label: 'Secteur d\'activité',
          field: 'secteur',
          type: 'text',
          filterable: true,
        },
        {
          label: 'Arrondissement',
          field: 'commune',
          type: 'text',
          filterable: true,
        },
        {
          label: 'Date',
          field: 'date',
          type: 'date',
          filterable: true,
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd-MM-yyyy',
        },
        {
          label: 'Montant',
          field: 'montant',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Montant payé',
          field: 'montantPaye',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Date limite',
          field: 'dateLimite',
          type: 'date',
          filterable: true,
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd-MM-yyyy',
        },
        {
          label: 'Livraison',
          field: 'livraison',
          type: 'text',
          filterable: true,
        },
        {
          label: 'Action',
          field: 'action',
          type: 'text',
          filterable: true,
        },
      ],
      rows: [
        {
          reference: 'V1002',
          secteur: 'Agroalimentaire',
          commune: 'Arrondissement 2',
          date: '2022-02-12',
          montant: 850000,
          montantPaye: 450000,
          dateLimite: '2022-03-12',
          livraison: 'Livré',
          action: 'Voir',
        },
        {
          reference: 'V1003',
          secteur: 'BTP',
          commune: 'Arrondissement 1',
          date: '2022-02-14',
          montant: 1250000,
          montantPaye: 0,
          dateLimite: '2022-03-14',
          livraison: 'En cours',
          action: 'Voir',
        },
        {
          reference: 'V1004',
          secteur: 'Transport',
          commune: 'Arrondissement 3',
          date: '2022-02-15',
          montant: 720000,
          montantPaye: 720000,
          dateLimite: '2022-03-15',
          livraison: 'Livré',
          action: 'Voir',
        },
        {
          reference: 'V1005',
          secteur: 'Services',
          commune: 'Arrondissement 4',
          date: '2022-02-18',
          montant: 450000,
          montantPaye: 200000,
          dateLimite: '2022-03-18',
          livraison: 'Livré',
          action: 'Voir',
        },
        {
          reference: 'V1006',
          secteur: 'TIC',
          commune: 'Treichville',
          date: '2022-02-20',
          montant: 950000,
          montantPaye: 500000,
          dateLimite: '2022-03-20',
          livraison: 'Livré',
          action: 'Voir',
        },
        {
          reference: 'V1007',
          secteur: 'Industrie',
          commune: 'Arrondissement 4',
          date: '2022-02-22',
          montant: 2500000,
          montantPaye: 2500000,
          dateLimite: '2022-03-22',
          livraison: 'Livré',
          action: 'Voir',
        },
        {
          reference: 'V1008',
          secteur: 'Santé',
          commune: 'Bingerville',
          date: '2022-02-24',
          montant: 400000,
          montantPaye: 100000,
          dateLimite: '2022-03-24',
          livraison: 'Livré',
          action: 'Voir',
        },
        {
          reference: 'V1009',
          secteur: 'Tourisme',
          commune: 'Arrondissement 1',
          date: '2022-02-28',
          montant: 1750000,
          montantPaye: 0,
          dateLimite: '2022-03-28',
          livraison: 'Livré',
          action: 'Voir',
        },
        {
          reference: 'V1010',
          secteur: 'Commerce',
          commune: 'Arrondissement 2',
          date: '2022-03-01',
          montant: 600000,
          montantPaye: 600000,
          dateLimite: '2022-04-01',
          livraison: 'Livré',
          action: 'Voir',
        },
      ]
      ,
    }
  },
}
</script>

<style scoped>

</style>
